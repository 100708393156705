import type {ButtonState} from "./state";

export const pushButtonString = (state: ButtonState) => {
    let pushButtonSvgStr = `<svg xmlns="http://www.w3.org/2000/svg" width="94.86" height="151.64" viewBox="0 0 94.86 151.64">
  <g id="WIRES">
    <line id="PIN_GND" x1="30.79" y1="101.84" x2="30.79" y2="133.69" fill="none" stroke="#020101" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
    <line id="PIN_DATA" x1="46.37" y1="101.84" x2="46.37" y2="133.69" fill="none" stroke="#3d8938" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
  </g>
  <g id="BUTTON_NOT_PRESSED">
    <g>
      <path id="COLOR_BTN_NOT_PRESSED" d="M58,67.7a8,8,0,0,0-4-4.23,3.28,3.28,0,0,0-.12-.88c-1.19-4.41-6.4-7.41-11.06-8.32a3.72,3.72,0,0,0-.75-.8c-1.63-1.34-3.32-3.23-5.37-3.88a21.06,21.06,0,0,0-3-.31c-1.21-.2-2.35-.74-3.57-.85-2.16-.2-4.31,1-6.27,1.88-.65.14-1.3.29-1.94.48A19.32,19.32,0,0,0,19.51,52a3.32,3.32,0,0,0-.74.14l-.11,0a3.76,3.76,0,0,0-2.36,2.1,3.94,3.94,0,0,0-1.62,1.29c-2.21,2.93-1.1,7-1.12,10.44,0,4.34-.14,8.61,1.37,12.74,1,2.74,4.72,3.56,6.45,1.5,0,.17,0,.33,0,.5.11,1.85,1.62,4,3.73,3.74,2.31-.24,4.71-.7,7-.73,1.7,0,3.08.51,4.76-.1A4.32,4.32,0,0,0,38,83.06c0,.54.08,1.08.1,1.64a33.18,33.18,0,0,0,1,5.27c0,1.67-.07,3.35-.11,5-.1,4.81,7.37,4.81,7.48,0l0-1.63a3.54,3.54,0,0,0,.29-1.68,17.16,17.16,0,0,0-.21-1.91q.16-8.07.34-16.14c0-1.47.16-3,.24-4.6l.18.06c.86.19,3.85,1.66,3.44.6C52.52,74.13,59.75,72.2,58,67.7Z" transform="translate(-3.58 -12.42)" fill="#a424d3"/>
      <path d="M58.27,65,41.65,49.58l-.11-.08a16.52,16.52,0,0,0-7.24-2.9,29.25,29.25,0,0,0-3.62-.23c-3.71,0-8.81.73-12.82,4.05-3.49,2.89-5.41,7.14-5.73,12.63V76.34A5.61,5.61,0,0,0,20.92,81a5.51,5.51,0,0,0,5.36,4.59,5.36,5.36,0,0,0,4.12-2,5.69,5.69,0,0,0,4.19,2,5.57,5.57,0,0,0,3-.87v9.46a5.47,5.47,0,1,0,10.91,0V71.65l1.93,1.47A5.65,5.65,0,0,0,58.31,73a5.64,5.64,0,0,0,0-8Zm-2,6a2.78,2.78,0,0,1-3.89,0,1,1,0,0,0-.15-.14l-4.33-3.28a1.46,1.46,0,0,0-1.53-.15,1.5,1.5,0,0,0-.81,1.31V94.18a2.57,2.57,0,1,1-5.09,0V73.09a1.46,1.46,0,0,0-2.91,0v6.55a3.21,3.21,0,0,1-3,3,3.12,3.12,0,0,1-2.85-3v-6.9a1.46,1.46,0,1,0-2.91,0v7.2a2.55,2.55,0,1,1-5.09,0v-7.2a1.46,1.46,0,1,0-2.91,0V76a3.32,3.32,0,0,1-3,3.09A2.75,2.75,0,0,1,15,76.34V63.18c0-.47.07-.93.12-1.37.49-4,2-7,4.56-9.15,4.73-3.92,11.52-3.52,14.21-3.17a13.93,13.93,0,0,1,5.8,2.28L56.26,67.1a2.76,2.76,0,0,1,0,3.89Z" transform="translate(-3.58 -12.42)"/>
    </g>
    <rect x="23.79" y="91.12" width="29.7" height="10.72" fill="#fd7476" stroke="#020101" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
    <line x1="13.97" y1="101.84" x2="63.31" y2="101.84" fill="none" stroke="#020101" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
    <path d="M20.37,48.91" transform="translate(-3.58 -12.42)"/>
  </g>
  <g id="BUTTON_PRESSED">
    <g>
      <path id="COLOR_BTN_PRESSED" d="M58,76.75a8,8,0,0,0-4-4.23,3.73,3.73,0,0,0-.12-.88c-1.2-4.41-6.4-7.41-11.07-8.31a3.49,3.49,0,0,0-.74-.81c-1.63-1.34-3.32-3.23-5.38-3.88a20.92,20.92,0,0,0-3-.31c-1.21-.2-2.35-.74-3.58-.85-2.15-.2-4.31,1-6.26,1.88-.66.14-1.31.29-1.94.49A17,17,0,0,0,19.51,61a3.22,3.22,0,0,0-.74.14l-.11,0a3.72,3.72,0,0,0-2.36,2.11,3.83,3.83,0,0,0-1.62,1.28c-2.22,2.93-1.1,7-1.12,10.44,0,4.34-.14,8.61,1.37,12.74,1,2.74,4.72,3.56,6.45,1.5,0,.17,0,.33,0,.5.12,1.86,1.63,4,3.74,3.74,2.31-.24,4.7-.7,7-.73,1.71,0,3.08.51,4.77-.1A4.32,4.32,0,0,0,38,92.11c0,.54.08,1.08.1,1.64a34.32,34.32,0,0,0,1,5.27c0,1.67-.07,3.35-.1,5-.1,4.81,7.37,4.81,7.47,0,0-.54,0-1.08,0-1.62a3.73,3.73,0,0,0,.29-1.69,15.09,15.09,0,0,0-.22-1.91q.18-8.07.35-16.14c0-1.46.16-3,.24-4.6l.18.06c.86.2,3.85,1.66,3.44.6C52.51,83.18,59.74,81.25,58,76.75Z" transform="translate(-3.58 -12.42)" fill="#a424d3"/>
      <path d="M58.27,74.06,41.64,58.64l-.1-.09a16.58,16.58,0,0,0-7.24-2.9,29.3,29.3,0,0,0-3.63-.23c-3.71,0-8.8.73-12.81,4-3.49,2.9-5.42,7.14-5.73,12.63V85.39a5.6,5.6,0,0,0,8.79,4.66,5.51,5.51,0,0,0,5.35,4.59,5.38,5.38,0,0,0,4.13-2,5.46,5.46,0,0,0,7.15,1.09v9.46a5.47,5.47,0,1,0,10.91,0V80.7l1.93,1.47a5.66,5.66,0,0,0,3.91,1.58A5.67,5.67,0,0,0,60,78.09,5.65,5.65,0,0,0,58.27,74.06Zm-2,6a2.76,2.76,0,0,1-3.88,0,.64.64,0,0,0-.15-.13l-4.34-3.29a1.44,1.44,0,0,0-1.52-.15,1.48,1.48,0,0,0-.81,1.31v25.45A2.76,2.76,0,0,1,43,106.14a2.72,2.72,0,0,1-2.54-2.91V82.14a1.46,1.46,0,0,0-2.91,0v6.55a3.21,3.21,0,0,1-3,3,3.12,3.12,0,0,1-2.86-3v-6.9a1.46,1.46,0,1,0-2.91,0V89a2.55,2.55,0,1,1-5.09,0v-7.2a1.45,1.45,0,1,0-2.9,0v3.26a3.33,3.33,0,0,1-3,3.09A2.75,2.75,0,0,1,15,85.39V72.23c0-.47.07-.93.12-1.37a13.46,13.46,0,0,1,4.56-9.15c4.73-3.92,11.51-3.52,14.21-3.17a13.93,13.93,0,0,1,5.8,2.28L56.25,76.15a2.74,2.74,0,0,1,0,3.89Z" transform="translate(-3.58 -12.42)"/>
    </g>
    <rect x="23.78" y="97.7" width="29.7" height="4.2" fill="#fd7476" stroke="#020101" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
    <line x1="13.96" y1="101.9" x2="63.31" y2="101.9" fill="none" stroke="#020101" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
    <path d="M20.36,46" transform="translate(-3.58 -12.42)"/>
  </g>
  <g id="TEXT">
    <text id="BUTTON_PRESSED_TEXT" transform="translate(0 15.82) scale(0.91 1)" font-size="18" font-family="Biryani-SemiBold, Biryani" font-weight="600">Button <tspan x="0" y="21.6">Pressed</tspan></text>
    <text id="PIN_TEXT" transform="translate(50.37 126.59)" font-size="24" font-family="Biryani-SemiBold, Biryani" font-weight="600">A15</text>
    <text transform="translate(11.84 129.75)" font-size="36" font-family="Biryani-SemiBold, Biryani" font-weight="600">-</text>
    <text onClick="alert('You have touched me')" id="BUTTON_TEXT" transform="translate(0.9 30.49) scale(0.91 1)" font-size="18" font-family="Biryani-SemiBold, Biryani" font-weight="600">Button </text>
  </g>
</svg>
`
    return(pushButtonSvgStr)
}
